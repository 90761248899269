.modal {
  &__content {
    min-height: 200px;
    min-width: 578px;
  }

  &__title {
    margin-bottom: 10px;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;

    margin-top: 18px;

    button + button {
      margin-left: 15px;
    }
  }

  &__confirm_btn {
    min-width: 298px;
  }
}
