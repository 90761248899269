@import "../../styles/variables";

.root {
  min-width: 176px !important;
  border-radius: 4px !important;
}

.single-value {
  &__label {
    font-family: SF Pro Display;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;

    /* identical to box height */

    color: #cccccc;
  }

  &__value {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;

    /* identical to box height */

    color: $primary;
  }
}
