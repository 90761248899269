@import "../../styles/variables";

.root {
  display: flex;
  margin-bottom: 28px;
}

.avatar {
  width: 49px;
  height: 49px;
  border-radius: 49px;
  object-fit: cover;
  margin-right: 18px;
}

.content {
  flex-grow: 1;
}

.textarea {
  background: #ffffff;
  border: 1px solid #acacac;
  box-sizing: border-box;
  border-radius: $border-radius;
  width: 100%;
  padding: 14px 10px;
  resize: none;

  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;

  &:focus,
  :active {
    outline: none;
  }
}

.bottom {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}

.datetime {
  background: #ffffff;
  border: 1px solid $primary-mid;
  box-sizing: border-box;
  border-radius: $border-radius;
  padding: 8px 16px 8px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: SF Pro Display;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: $primary;
}
.row{
  display: flex;
  align-items: center;
  gap: 10px;
}

.checkbox{
  display: flex;
  align-items: center;
}

.checkbox>input{
  margin-right: 5px;
  width: 18px;
  height: 18px;
}

.clock_icon {
  margin-right: 5px;
}
