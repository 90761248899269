@import "../../../../styles/variables";

.DeliveryPlanner__Filter {
  .Select {
    &__control {
      border: 1px solid #cccccc;
      box-sizing: border-box;
      border-radius: 3px !important;
      min-width: 267px;
    }

    &__placeholder {
      color: #424242;
    }

    &__menu {
      min-width: 170px;
      right: -5px;
  
    }

    &__group-heading {
      text-transform: none;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: $primary-l;
     
    }

    &__option {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: $primary;
   

      &::before {
        content: "";
        display: block;
        border: 1px solid $gray-2;
        box-sizing: border-box;
        border-radius: 3px;
        width: 20px;
        height: 20px;
        margin-right: 5px;
      }

      &--is-focused {
        background-color: #fff;
      }

      &--is-selected {
        background-color: #fff;

        &::before {
          background-color: $green-2;
          background-image: url("../../icons/checkmark.svg");
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    }
  }
}

.locationIcon{
  width: 20px;
  height: 20px;
  margin-left:5px;
}
.checkbox {
  margin-top: 1px;
  padding-top: 7px;
  padding-bottom: 7px;
  display: flex;
  align-items: center;
  margin-right: 0px;

  input {
    @media print {
      display: none;
    }
  }
}

.display-row{
  display: flex;
  margin: 10px 0;
}

.Time_Select {
  .Select {
    &__control {
      border: 1px solid #cccccc;
      box-sizing: border-box;
      border-radius: 3px !important;
      width: 115px;
      margin-left: 7px;
    }

    &__placeholder {
      color: #424242;
    }

    &__menu {
      min-width: 170px;
      right: -5px;
  
    }

    &__group-heading {
      text-transform: none;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: $primary-l;
     
    }

    &__option {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: $primary;
   

      &::before {
        content: "";
        display: block;
        border: 1px solid $gray-2;
        box-sizing: border-box;
        border-radius: 3px;
        width: 20px;
        height: 20px;
        margin-right: 5px;
      }

      &--is-focused {
        background-color: #fff;
      }

      &--is-selected {
        background-color: #fff;

        &::before {
          background-color: $green-2;
          background-image: url("../../icons/checkmark.svg");
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    }
  }
}

.timers{
  margin-left: 27px;
}

.timer{
  margin-left: 6px;
}
.customInput_Filter{
  border-radius: 3px;
  width: 145px !important;
}

.select-text{
  margin: 0;
}
.select-row{
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
}
.select-placeholder-text{
  color: #757575;
  font-size: 15px;
  font-style: normal;
  font-weight:normal;
  overflow: hidden;
   display: -webkit-box;
   -webkit-line-clamp: 1; /* number of lines to show */
           line-clamp: 1; 
   -webkit-box-orient: vertical;
  
}

.Table_Select{
  margin-left: 10px;
  .Select {
    &__control {
      border: 1px solid #cccccc;
      box-sizing: border-box;
      border-radius: 3px !important;
      min-width: 135px;
    }
  
    &__placeholder {
      color: #424242;
    }
  
    &__menu {
      min-width: 170px;
      right: -5px;
  
    }
  
    &__group-heading {
      text-transform: none;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: $primary-l;
     
    }
  
    &__option {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: $primary;
   
  
      &::before {
        content: "";
        display: block;
        border: 1px solid $gray-2;
        box-sizing: border-box;
        border-radius: 3px;
        width: 20px;
        height: 20px;
        margin-right: 5px;
      }
  
      &--is-focused {
        background-color: #fff;
      }
  
      &--is-selected {
        background-color: #fff;
  
        &::before {
          background-color: $green-2;
          background-image: url("../../icons/checkmark.svg");
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    }
  }
}
