.root {
  display: grid;

  &.columns-1 {
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
  }

  &.columns-2 {
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
  }

  &.columns-3 {
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
  }

  &.columns-4 {
    grid-template-columns: repeat(4, 1fr);
  }

}

@media (max-width: 768px) {
  .root {
    &.columns-2 {
      grid-template-columns: repeat(1, 1fr);
      gap: 16px;
    }
  
    &.columns-3 {
      grid-template-columns: repeat(1, 1fr);
      gap: 16px;
    }
    &.columns-4 {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
