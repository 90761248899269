@import "../../styles/variables";

.root {
}

.top_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.count_wrapper {
  margin-right: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
}
.countText {
  margin-left: 5px;
}
.categoryButton {
  min-width: 150px;
  margin-right: 7px;
}
.appCategoryButton {
  min-width: 150px;
}
.topCategoryWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.preferenceDate{
  display: flex;
  align-items: center;
}
.label{
  margin-right: 10px;
  opacity: 0.6;
}
.row{
  display: flex;
  justify-content: space-between;
}

.Location_Select{
  margin-left: 10px;
  width: 150px;
  
}

.input{
  border-radius: 3px;
  min-width: 350px;
}

.locationIcon{
  width: 20px;
  height: 20px;
  margin-left:5px;
}
.customInput{
  border-radius: 3px;
  width: 250px !important;
}

.selectCon{
  min-width: 155px !important;
  margin-left: 10px;
}


.list_wrapper {
  min-width: 550px;
  overflow-y: scroll;
  max-height: 90vh;
}
.addBtn{
  margin-left: auto;
  margin-top: 30px;
}
.select{
  margin-bottom: 20px;
}

.chart{
  width: 27px;
  height: 27px;
  border: 1px solid #747474;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  margin-right: 16px;
  cursor: pointer;
}
.tabs{
  flex: 1;
}
.pieChart{
  width: 50%;
  margin: 30px 0;
}
.chart_wrapper{
  display: flex;
  align-items: center;
  gap: 30px;
}
.chartItem{
  display: flex;
  align-items: center;
  gap: 10px;
}

.reportBtn{
  position: absolute;
  z-index: 1;
  right: 15px;
}




