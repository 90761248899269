@import "../../styles/variables";

.ScriptStatusSelect {
  &__control {
    display: inline-flex !important;
    min-height: 30px !important;
    border-radius: 30px !important;
    border: none !important;

    &--is-focused {
      border: none !important;
      outline: none !important;
      box-shadow: none !important;
    }
  }

  &__indicator-separator {
    display: none;
  }

  &__dropdown-indicator {
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    svg {
      fill: $primary !important;
    }
  }

  &__value-container {
    display: flex !important;
    flex-wrap: nowrap !important;
    padding: 0 10px !important;

    &:before {
      display: block;
      content: "";
      width: 19px;
      height: 19px;
      border-radius: 19px;
      background-color: rgba(255, 255, 255, 0.3);
      background-image: url("./icons/rx.svg");
      background-position: center;
      background-repeat: no-repeat;
      margin-right: 4px;
    }
  }

  &__single-value {
    font-size: 14px;
    color: $primary !important;
  }
}

.missing_transfer,
.no_script_available {
  .ScriptStatusSelect__control {
    background-color: #f9dde5;
  }
}

.cancel_script {
  .ScriptStatusSelect__control {
    background-color: #f9e3d7;
  }
}
.picked_up {
  .ScriptStatusSelect__control {
    background-color: #a8f574;
  }
}
.waiting_for_pickup,.request_transfer {
  .ScriptStatusSelect__control {
    background-color: #f5e274;
  }
}

.filled{
  .ScriptStatusSelect__control {
    background-color: #d6b6fb;
  }
}

.paid,

.billed,
.delivered,
.otc_status,
.ready_for_delivery,
.completed {
  .ScriptStatusSelect__control {
    background-color: #d8f5c8;
  }
}
.delivery_partner{
  .ScriptStatusSelect__control {
    background-color: #b9baf9;
  }
}
.incomplete{
  .ScriptStatusSelect__control {
    background-color: #eeeeee;
  }
  
}
.archive{
  .ScriptStatusSelect__control {
    background-color: rgb(200, 200, 200);
  }
}
