@import "../../styles/variables";

.checkbox {
  appearance: none;
  content: "";
  position: relative;
  display: block;
  background: #ffffff;
  border: 1px solid #dde2e5;
  border-radius: 3px;
  width: 16px;
  height: 16px;
  box-sizing: border-box;

  &:checked {
    background-color: $primary;
    background-image: url("./icons/checked.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 12px;
    border: 1px solid $primary;
  }
}
