@import "../../styles/variables";

.root {
}

.list_header {
  border-bottom: 1px solid #eeeeee;
}
.list_wrapper {
  min-width: 550px;
}
.formWrapper {
  margin-top: 50px;
}
.buttonWrap {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
.pickerWrapper {
  margin-top: 5px;
}
.top_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.count_wrapper {
  display: flex;
  flex-direction: row;
  margin-right: 15px;
}
