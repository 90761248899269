.picker_wrapper {
  position: relative;
}

:global(.react-datepicker__time-box) {
  width: 76px !important;
}
:global(.react-datepicker__time-container) {
  width: 76px !important;
}
.wrapper{
  width: 100%;
  // position: relative;
}
.datePicker{
  width: 100%;
}

.closeIcon{
  position: absolute;
  z-index: 1;
  right: 5px;
  top:11px;
  width: 18px;
  height: 18px;
  cursor: pointer;
}


.clearButtonClassName::after {
  background-color: transparent !important;
  content: url('./clear.svg') !important;
  outline: none;
}
.customInput{
  padding-left: 25px !important;
}

