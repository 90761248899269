.wrapper {
  display: block;
  // position: relative;
  overflow: hidden;
  &--thumbnail {
    width: 32px;
    height: 32px;
    margin-top: -4px;
    margin-bottom: -4px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  &--medium {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 200px;
    width: 100%;
    background-color: #eee;
    border-radius: 8px;
    transition: .2s;

    svg {
      width: 80px;
      height: 80px;
      transform: translateY(0) scale(1);
      transition: .2s;
    }
  }
  &--uploadable {
    cursor: pointer;

    .note {
      text-align: center;
      color: #939791;
      padding: 5px 8px;
      border-radius: 5px;
      background-color: #dededf;
      opacity: 0;
      transform: translateY(20px);
      transition: .2s;
      margin: 0;
    }

    &:hover {
      background-color: #dededf;

      .note {
        opacity: 1;
        transform: translateY(-10px);
      }

      svg {
        transform: translateY(-16px) scale(.9);
      }
    }
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
  input[type=file] {
    position: absolute;
    left: -10000px;
    width: 1px;
    height: 1px;
    opacity: 0;
    z-index: 1;
  }
}
.close {
  position: absolute;
  top: 5px;
  right: 10%;
  z-index: 10;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
}
