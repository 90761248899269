@import "../../../../styles/variables";

.form {
  max-height: 0;
  // flex-grow: 1;

  box-sizing: border-box;
  // overflow: hidden;

  // transition: all 250ms ease-in-out;

  &.--expanded {
    max-height: 500px;
  }

  &__content {
    // display: grid;
    // grid-template-columns: 1fr 1fr;
    // grid-auto-rows: 75px;
    // grid-gap: 0;
    // grid-template-columns: 70px auto;
    // grid-template-rows: repeat(4, 40px);
    // grid-gap: 10px 5px;

    padding: 26px 10px 16px 15px;

    

    input,
    .Select__control {
      border-radius: 8px !important;
    }
  }
}

.label {
      // display: flex;
      // align-items: center;
      // padding: 0;
      width: 70px;
      font-size: 14px;
      // line-height: 17px;
      color: $primary-l;
    }

.option {
  display: flex;
  align-items: center;

  img {
    margin-right: 10px;
  }
}

.actions {
  display: flex;
  margin-top: 20px;
  gap: 12px;
  margin-left: 65px;
}
.driverStatusWrap {
  margin-left: 5px;
}
.driverStatus{
  font-size: small;
  color: #3dcc4a;
}

.icon_wrapper {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 15px;
  left: 6px;
  z-index: 1;
}
.select{
  width: 100%;
  z-index: 2;
}

.google_input {
  border: 1px solid $primary-low;
  box-sizing: border-box;
  border-radius: 3px;
  width: 100%;
  // background-color: #fff;
  margin-left: 32px;
}

.root{
  width: 100%;
  position: relative;
}
.calendarRoot{
  width: 100%;
}

.formRow{
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 15px;
}

.wrapper{
  margin-top: 0;
}
