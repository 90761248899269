.filterBtn{
    cursor: pointer;
    height: 32px;
    padding: 0 8px;
    display: flex;
    gap: 10px;
    align-items: center;
    margin-left: 10px;
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    min-width: 90px !important;
  }
  .row{
    display: flex;
  }
  .filterBtnContainer{
    display: flex;
    flex-direction: row;
    gap: 12px;
    justify-content: flex-end;
  }
  .filterModal{
    width: 600px;
  }
  .filterContainer{
    display: flex;
    flex-direction: column;
    margin: 20px 0;
    z-index: 1000;
  }
  .filterItem{
    display: flex;
    gap: 12px;
    margin-bottom: 30px;
    margin-top: 6px;
  }
  .filterInput{
    width: 100%;
    margin-top:0px;
  }
  