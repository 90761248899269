.ck-file-dialog-button{
  position: relative!important;
}
.ck-file-dialog-button .image-upload-btn{
  width: 100% !important;
  height: 100% !important;
  position: absolute!important;
  top: 0!important;
  right: 0!important;
  z-index: 5!important;
  cursor: pointer!important;
  background-image: url("./icons/add.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 22px;
  background-color: #fafafa;
  &:hover{
    background-color: #e6e6e6;
  }
}
.ck .ck-content{
  height: 500px;
  overflow-y: auto;

}

.description .ck-editor__main {
	height: 200px;

	.ck-editor__editable_inline {
		min-height: 190px;
	}
}

.ck-editor__editable_inline {
	height: 100%;
}

.has-error{
  .ck.ck-editor__main>.ck-editor__editable:not(.ck-focused){
    border-color: #f5222d;
  }
}

.ant-form-explain {
  color: #f5222d;
  font-size: 12px;
  font-weight: 500;
}