$padding-sm: 14px;
$padding-md: 24px;
$padding-lg: 40px;

.root {
  flex-grow: 1;
  flex-basis: 100px;

  &.dark {
    background-color: #f9f9fd;
  }

  &.tight {
    padding: 36px 12px 16px;
  }

  &.px-sm {
    padding-left: $padding-sm;
    padding-right: $padding-sm;
  }

  &.px-md {
    padding-left: $padding-md;
    padding-right: $padding-md;
  }

  &.px-lg {
    padding-left: $padding-lg;
    padding-right: $padding-lg;
  }

  &.pt-sm {
    padding-top: $padding-sm;
  }

  &.pt-md {
    padding-top: $padding-md;
  }

  &.pt-lg {
    padding-top: $padding-lg;
  }

  &.pb-sm {
    padding-bottom: $padding-sm;
  }

  &.pb-md {
    padding-bottom: $padding-md;
  }

  &.pb-lg {
    padding-bottom: $padding-lg;
  }
}
