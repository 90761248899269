@import "../../../../styles/variables";


.root {
  display: block;
  position: relative;
  background-color: #fff;
  border: 1px solid $primary-low;
  box-sizing: border-box;
  border-radius: $border-radius;
  margin: 20px 0;
  padding: 15px 15px 12px;
  cursor: pointer;
  transition: .2s;
}
.center{
  margin: 0 auto;
  align-items: center;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid $primary-low;
  padding: 10px 0;
  margin-bottom: 10px;

}
.root>p{
  margin: 5px 0;
  font-size: 13px;
  margin-bottom: 13px;
}
.center>p{
  margin: 5px 0;
  font-size: 12px;
  font-weight: bold;
}

.clientHead{
  text-decoration: underline;
  font-weight: bold;
  margin-bottom: 3px !important;
}
.table{
  border: 0.5px solid $primary-low;
  margin-top: 15px;
}
.boldtext{
  font-weight: bold;
}
.tableRow>div{
  border: 0.5px solid $primary-low;
  flex: 1;
  padding: 7px;
}
.tableRow>div>p{
  margin: 0;
  font-size: 12px;
}
.tableRow{
  display: flex;
}
.arrow_bottom {
  transform: scale(1.5);
}
.dropdown{
  border: 1px solid $primary-low;
  display: flex;
  justify-content: space-between;
  border-radius: $border-radius;
  margin: 20px 0;
  padding: 0 15px;
  cursor: pointer;
}
.dropdown>p{
  font-size: 16px;
}
.row{
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  gap: 20px;
}
.row>div>p{
  font-size: 12px;
  margin: 7px 0;
}

.row>p{
  margin: 0;
  font-size: 12px;
}

.row>p:nth-child(2){
  min-width: 180px;
}

.signatureImage{
  width: 200px;
  height: 120px;
}
.print{
  display: flex;
  justify-content: flex-end;
}

