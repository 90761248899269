@import "../../styles/variables";

.title {
  display: flex;

  h2 {
    margin-bottom: 6px;
  }
}

.tabs {
  margin-top: 28px;
}

.content {
  display: grid;
  grid-template-columns: auto 320px;
  gap: 15px;
}

.info_column {
  margin-top: 64px;
}

.contact_label {
  display: flex;
  justify-content: space-between;
}

.edit_link {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;

  /* identical to box height */
  text-decoration-line: underline;

  color: $primary-l;
}
