.container {
  padding: 20px;
  display: flex;
  gap: 20px;
  padding-top: 0px;
}
.left{
  width: 75%;
}
.right {
  width: 30%;
  margin-top: 15px;
  flex-direction: column;
}
.title{
  margin-bottom: 20px;
}
.image{
  border: 1px solid #d9d9d9;
  border-radius: 8px;

}
.error{
  border: 1px solid #f5222d;
}
.errorMessage{
  color: #f5222d;
  font-size: 12px;
  text-align: right;
  margin-top: 3px;
}
.modal{
  min-width: 600px;
}
.deleteButton{
  margin-top: 50px;
  margin-left: auto;
}
.deleteText{
  margin-top: 20px;
  font-size: 16px;
} 
.status{
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-end;
  margin-top: 20px;
}