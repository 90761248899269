@import "../../../styles/variables";

.accordion {
  // padding: 24px 0px 12px 0px;
  padding-top: 20px;
  margin-bottom: 30px;
  margin-bottom: 0;
  padding-bottom: 20px;
  display: flex;
  overflow-y:scroll ;
  flex-direction: column;
  justify-content: space-between;

  &__title {
    margin: 0 0 .5em 0 !important;
    font-size: 20px;
    padding-left: 20px;
  }

  .row{
    display: flex;
    justify-content: space-between;
  }
  .close_icon{
    width: 20px;
    height: 20px;
    margin-left: auto;
    border: 1px solid #ff3b24;
    border-radius: 20px;
    cursor: pointer;
  }
  .card{
    padding: 10px 12px;
  
    border-radius: 3px;
    transition: .2s;
    background-color: desaturate(darken($gray-6, 1), 4);
  }

  .card:hover {
    background-color: desaturate(darken($gray-6, 7), 8);
  }

  &__item_header {
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    letter-spacing: .05em;
    margin-bottom: 15px;
  }

  &__item_content {
    border: 1px solid darken($gray-6, 5);
    padding: 0 16px 16px 16px;
  }

  &__item_header_icon_rotated {
    transform: rotate(90deg);
  }
}

.accordion__inner {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
  border-radius: 8px;
  margin-left: 20px;
  margin-right: 20px;
  overflow-y: scroll;
}

.switchForm {
  margin-top: 25px;
  margin-bottom: 10px;
}

.switchContainer {
  display: flex;
  justify-content: flex-end;
}

.label {
  display: block;
  margin-bottom: 6px;
  font-size: 16px;
  line-height: 17px;
  color: #000000;
}
.warningText {
  display: block;
  margin-top: 15px;
  margin-bottom: 6px;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}
.actionWrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
.buttonWrap {

}
.list_wrapper {
  min-width: 550px;
}
.modalWrapper {
  margin-top: 30px;
}
.cancelButton {
  margin-right: 15px;
}

.googleInput{
  margin-top: 2px;
}
.modalHeader{
  text-align: center;
  margin-top: 40px;
  font-size: 20px;
}
.delete_wrapper{
  min-width: 500px;
}
.delete{
  margin-left: auto;
}
