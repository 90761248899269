.root {
  object-fit: cover;

  border-radius: 50%;
  margin-right: 9px;

  &.--sm {
    width: 20px;
    height: 20px;
  }

  &.--md {
    width: 50px;
    height: 50px;
  }
}
