.leaflet-routing-container{
    display: none;
}
.leaflet-div-icon{
    background-color: transparent;
    border-width: 0;
}
.my-div-icon{
    background-color: #0072b3;
    border-radius: 30px;
    width: 30px;
    height: 30px;
    color: #fff;
    justify-content: center;
    align-items: center;
    display: flex;
    font-weight: 700;
    font-size: 16px;
    border-width: 0;
    transform: translateY(-30px);
}

.start{
    background-color: red;
}
.completed_icon{
    background-color: green;
}

.completed_icon::after{
    border-color:green transparent transparent transparent !important;
}
.start::after{
    border-color:red transparent transparent transparent !important;
}

.my-div-icon::after {
    content: "";
    position: absolute;
    top: 29px;
    border-width: 5px;
    border-style: solid;
    border-color: #0072b3 transparent transparent transparent;
  }