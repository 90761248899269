@import "../../styles/variables";

.root {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  height: 22px;
  border-radius: 46px;
  font-size: 14px;
  line-height: 1;


  padding: 2px 12px;
  box-sizing: border-box;
  color: #fff;
  background-color: gray;

  &.--default {
    background-color: $status-default;
  }

  &.--gray {
    background-color: #eeeeee;
    color: #666666;
  }

  &.--green {
    background-color: #6ECF97;
  }
  &.--yellow {
    background-color: #f5e274;
  }

  &.--iris {
    background-color: $iris-60;
  }

  &.--iris_light {
    background-color: #d6b6fb;
  }

  &.--iris_dark {
    background-color: $iris-100;
  }

  &.--fuschia {
    background-color: $fuschia-80;
  }

  &.--critical {
    background-color: $status-critical;
  }
}
