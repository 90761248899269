@import "../../../styles/variables";

.root {
  display: flex;
  padding: 0 15px;
  margin-top: 15px;
  border-bottom: 1px solid #eeeeee;
}

.tab {
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  color: $primary-l;
  padding: 8px 2px 12px;
  margin: 0 28px;
  cursor: pointer;
  text-decoration: none;

  &:first-child {
    margin-left: 0;
  }

  &.--active {
    color: $black;
    border-bottom: 2px solid $primary;
  }
}
