.role {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  font-size: 13px;
  line-height: 16px;

  color: #102145;
  height: 30px;
  width: 140px;
  border-radius: 30px;
  background: #eeeeee;
}
