.root {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2.5px 6px;

  height: 20px;

  border: 1px solid #eeeeee;
  box-sizing: border-box;
  border-radius: 42px;

  font-weight: 500;
  font-size: 11px;
  line-height: 13px;

  color: #cccccc;

  cursor: pointer;
}

.arrow {
  margin-left: 4px;
}
