@import "../../styles/variables";

.root {
  display: flex;
  border: 1px solid $primary-low;
  box-sizing: border-box;
  box-shadow: 0px 0px 45px rgba(0, 0, 0, 0.05);
  border-radius: $border-radius;

  & + & {
    margin-top: 12px;
  }

  & > :first-child {
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
  }

  & > :last-child {
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
