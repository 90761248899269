.header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  &__title {
    flex-grow: 1;
    padding-right: 80px;
  }
}

.form {
  min-width: 500px;
  margin-top: 30px;
}

.actions {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 30px;
}