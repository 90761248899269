.root {
  position: relative;
  width: 40px;
  height: 40px;
  overflow: hidden;
}

.spinner,
.spinner:after {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  box-sizing: border-box;
}

.spinner {
  position: absolute;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 4px solid rgba(0, 0, 0, 0.2);
  border-right: 4px solid rgba(0, 0, 0, 0.2);
  border-bottom: 4px solid rgba(0, 0, 0, 0.2);
  border-left: 4px solid #ffffff;

  transform: translateZ(0);

  animation: load8 1.1s infinite linear;
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
