@import "styles/variables";

.SupportStatusSelect {
  &__control {
    display: inline-flex !important;
    min-height: 30px !important;
    border-radius: 30px !important;
    border: none !important;

    &--is-focused {
      border: none !important;
      outline: none !important;
      box-shadow: none !important;
    }
  }

  &__indicator-separator {
    display: none;
  }

  &__dropdown-indicator {
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    svg {
      fill: $primary !important;
    }
  }

  &__value-container {
    display: flex !important;
    flex-wrap: nowrap !important;
    padding: 0 10px !important;
  }

  &__single-value {
    font-size: 14px;
    color: $primary !important;
  }
}

.active {
  .SupportStatusSelect__control {
    background-color: #f9dde5;
  }
}

.cancel_script {
  .SupportStatusSelect__control {
    background-color: #f9e3d7;
  }
}

.archived {
  .SupportStatusSelect__control {
    background-color: #d8f5c8;
  }
}
