@import "../../styles/variables";

.root {
}

.top_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.count_wrapper {
  display: flex;
  flex-direction: row;
  margin-right: 15px;
}
.countText {
  margin-left: 5px;
}
.categoryButton {
  min-width: 150px;
  margin-right: 7px;
}
.appCategoryButton {
  min-width: 150px;
}
.topCategoryWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.preferenceDate{
  display: flex;
  align-items: center;
}
.row{
  display: flex;
  gap: 10px;
}

.Location_Select{
  margin-left: 10px;
  width: 150px;
  
}

.label {
  display: block;
  font-size: 16px;
  line-height: 17px;
  margin-top: 22px;
  color: #000000;
  margin-bottom: 6px;
  font-weight: 500;
}

.link {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  height: 35px;
  color: $primary;
  width: 100%;
  box-sizing: border-box;
  text-decoration: none;
  transition: all 250ms ease-in-out;

  &.active {
    background-color: $status-default;

    color: $white;

    path[fill="black"],
    circle[fill="black"],
    path[fill="#222222"] {
      fill: $white;
    }

    path[stroke="black"],
    circle[stroke="black"],
    path[stroke="#222222"] {
      stroke: $white;
    }
  }
}

.input{
  border-radius: 3px;
  min-width: 350px;
}

.locationIcon{
  width: 20px;
  height: 20px;
  margin-left:5px;
}
.customInput{
  border-radius: 3px;
  width: 100%;

}

.selectCon{
  min-width: 135px !important;
  // max-width: 300px;
  width: 100%;
}
.selectStatus{
  margin-left: 10px;
}
.selectStatus>div{
  min-width: 180px !important;
  max-width: 180px !important;
}

.list_wrapper {
  min-width: 550px;
  overflow-y: scroll;
  max-height: 90vh;
}
.addBtn{
  margin-left: auto;
  margin-top: 30px;
}
.filterBtn{
  cursor: pointer;
  height: 38px;
  padding: 0 8px;
  display: flex;
  gap: 10px;
  align-items: center;
  margin-left: 10px;
  border: 1px solid #E0E0E0;
  border-radius: 4px;
  min-width: 90px !important;
}
.filterContainer{
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 20px 0;
  z-index: 1000;
}
.filterBtnContainer{
  display: flex;
  flex-direction: row;
  gap: 12px;
  justify-content: flex-end;
}
.filterModal{
  width: 600px;
}

