@import "../../styles/variables";

.root {
  position: relative;
  &.--sm {
    height: $height-sm;
  }

  &.--md {
    height: $height-md;
  }

  &.--lg {
    height: $height-lg;
  }
}

.input {
  display: flex;
  align-items: center;
  border: 1px solid $primary-low;

  box-sizing: border-box;
  border-radius: 3px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  padding: 0 8px;
  width: 100%;

  &.--read-only {
    opacity: .6;
    cursor: not-allowed;
  }

  &:focus,
  &:active {
    outline: none;
  }

  &.--with-icon {
    padding-left: 32px;
  }

  &.--with-error {
    border-color: $error;
  }

  &.--sm {
    height: $height-sm;
  }

  &.--md {
    height: $height-md;
  }

  &.--lg {
    height: $height-lg;
  }
}

.icon_wrapper {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 6px;
}
