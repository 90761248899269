@import "../../styles/variables";

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin: 0;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.p,
.label {
  &.--align-center {
    text-align: center;
  }
}

.h1 {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: 0.481894px;

  color: #000000;
}

.h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: $primary;
}

.h3 {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;

  color: $primary;
}

.h4 {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 22px;
  color: #000000;
}

.h5 {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;

  letter-spacing: 0.1px;
  color: $primary-low;
}

.h6 {
  color: $primary;
}

.p {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: black;
  margin: 0 0 10px;
}

.label {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  /* identical to box height */

  color: $primary-l;

  margin: 0 0 10px;
}

.a {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;

  letter-spacing: 0.1px;
  text-decoration-line: underline;

  color: $primary;
  cursor: pointer;
}

.strong {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}
