@import "../../styles/variables";

.list_wrapper {
  min-width: 550px;
  overflow-y: scroll;
  max-height: 90vh;
}
.label{
  margin-top: 20px;
  margin-bottom: 4px;
}
.buttonWrap{
  justify-content: flex-end;
  margin-top: 20px;
}

.textarea {
  display: flex;
  align-items: center;
  border: 1px solid $primary-low;
  font-family:sans-serif;
  box-sizing: border-box;
  border-radius: 3px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  padding: 5px 8px;
  width: 100%;
  min-height: 70px;
  &.--read-only {
    opacity: .6;
    cursor: not-allowed;
  }

  &:focus,
  &:active {
    outline: none;
  }
}