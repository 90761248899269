@import "../../styles/variables";

.root {
  display: flex;
  align-items: center;
  justify-content: center;

  background: #fbfaf8;
  border: 1px solid $primary-low;
  box-sizing: border-box;
  border-radius: $border-radius;
  width: 47px;
  height: 40px;
  cursor: pointer;

  & + & {
    margin-left: 10px;
  }

  &:disabled {
    border-color: #fff;
  }
}
