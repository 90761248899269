.header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  &__title {
    flex-grow: 1;
  }
}

.modal{
  max-width: 800px;
  width: 100%;
}

.form {
  min-width: 600px;
  max-height: 80vh;
  overflow-y: scroll;
  display: flex;
  justify-content: space-between;
}
.block{
  width: 46%;
  margin: 0 10px;
}
.seperator{
  width: 1.5px;
  background-color: #E9E9E9;
  top:60px;
  bottom: 25px;
  position: absolute;
  left: 50%;
}

.actions {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 45px;
}
.select{
  margin:0 2px;
}