@import "../../../styles/variables";

.root {
  margin: 16px 0;

  display: flex;
}

.details {
  margin-left: 16px;
}

.name {
  font-size: 18px;
  font-weight: 500;
}

.label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
}

.edit {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 10px;

  text-decoration-line: underline;

  color: $primary-l;
}
