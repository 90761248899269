.root {
  max-width: 780px;
  width: 100%;
  padding: 0 40px;
}

.button_container {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.link {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  text-decoration: none;

  color: #0068ed;
}

.signup {
  margin: 50px 0;
  text-align: center;
}

.message {
  max-width: 250px;
  margin: 50px auto;
}

.successImg{
  width: 150px;
  height: 150px;
  margin-bottom: 20px;
}
.logo{
  transform: rotate(90deg);
  height: 100px;
  overflow: hidden;
}