@import "../../styles/variables";

.root {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  background-color: rgba($color: $primary, $alpha: 0.8);
  z-index: 1000;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.images {
  margin-bottom: 20px;
}

.image {
  width: 552px;
  height: 365px;
  object-fit: contain;
  background-color: #c4c4c4;
  border-radius: 15px;

  & + & {
    margin-left: 48px;
  }
}

.button {
  background-color: #ffffff !important;
  border: 1px solid #cccccc !important;
}

.requested {
  color: #fff;
  height: 40px;
}

.close {
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
}
